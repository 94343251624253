<template>
  <div>
    <div class="skip-nav">
      <a href="#gnbWrap">주요 메뉴 바로가기</a>
      <a href="#container">본문 바로가기</a>
    </div>
    <div class="quick-menu">
      <ul class="quick-menu__wrap">
        <li @click="openQuickMessage(false)">
          <div class="btn-quick-alarm">
            <span class="blind">알람메세지</span>
            <div v-if="newMessageCnt > 0">
              <i class="icon-alarm">
                <span class="blind">새알람</span>
              </i>
            </div>
          </div>
        </li>
        <li @click="favorBuyerToggle(true)">
          <div class="btn-quick-like">
            <span class="blind">관심바이어</span>
          </div>
        </li>
        <li>
          <RouterLink to="/find-scambuyers" class="btn-quick-danger">
            <span class="blind">사기바이어</span>
          </RouterLink>
        </li>
        <template v-if="isLogin">
          <li>
            <div class="btn-quick-time">{{ getSessionTime2 }}</div>
          </li>
        </template>
      </ul>
    </div>

    <div class="wrap" id="wrap">
      <!-- pc header -->
      <header class="header-pc" :class="{ 'gnb-show': isNavShow === true }">
        <div class="header__inner">
          <h1 class="header__logo">
            <RouterLink to="/">
              <img src="@/assets/images/logo.png" alt="NTS 수출지원서비스" />
            </RouterLink>
          </h1>
          <nav class="gnb" id="gnbWrap">
            <ul class="gnb__wrap">
              <li class="gnb__depth01" @mouseover="showNav" @mouseleave="hideNav">
                <RouterLink to="/bm" class="gnb__a01" @click.native="hideNav">빅바이어 마케팅</RouterLink>
                <ul class="gnb__depth02">
                  <li>
                    <RouterLink
                      to="/bm/big-data"
                      class="gnb__a02"
                      @click.native="hideNav"
                    >빅데이터 바이어 발굴</RouterLink>
                  </li>
                  <li>
                    <RouterLink to="/bm/push" class="gnb__a02" @click.native="hideNav">마케팅 메시지 발송</RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to="/bm/history"
                      class="gnb__a02"
                      @click.native="hideNav"
                    >마케팅 메시지 발송내역</RouterLink>
                  </li>
                  <li>
                    <RouterLink to="/bm/tmp" class="gnb__a02" @click.native="hideNav">임시저장내역</RouterLink>
                  </li>
                </ul>
              </li>
              <li class="gnb__depth01" @mouseover="showNav" @mouseleave="hideNav">
                <RouterLink to="/buyer-lists" class="gnb__a01" @click.native="hideNav">바이어 정보</RouterLink>
                <ul class="gnb__depth02">
                  <li>
                    <RouterLink to="/buyer-lists" class="gnb__a02" @click.native="hideNav">바이어 조회</RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to="/find-scambuyers"
                      class="gnb__a02"
                      @click.native="hideNav"
                    >사기바이어 이메일</RouterLink>
                  </li>
                </ul>
              </li>
              <li class="gnb__depth01" @mouseover="showNav" @mouseleave="hideNav">
                <RouterLink to="/apply-reports" class="gnb__a01" @click.native="hideNav">해외기업 보고서</RouterLink>
                <ul class="gnb__depth02">
                  <li>
                    <RouterLink to="/apply-reports" class="gnb__a02" @click.native="hideNav">보고서 신청</RouterLink>
                  </li>
                  <li>
                    <RouterLink to="/report-lists" class="gnb__a02" @click.native="hideNav">보고서 신청내역</RouterLink>
                  </li>
                </ul>
              </li>
              <li class="gnb__depth01" @mouseover="showNav" @mouseleave="hideNav">
                <RouterLink to="/apply-consults" class="gnb__a01" @click.native="hideNav">수출바우처 컨설팅</RouterLink>
                <ul class="gnb__depth02">
                  <li>
                    <RouterLink to="/apply-consults" class="gnb__a02" @click.native="hideNav">컨설팅 신청</RouterLink>
                  </li>
                  <li>
                    <RouterLink to="/consult-lists" class="gnb__a02" @click.native="hideNav">신청내역</RouterLink>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          <div class="util-menu">
            <div class="util-menu__wrap">
              <div v-if="this.isLogin == false">
                <RouterLink to="/login" class="btn-login"></RouterLink>
              </div>
              <div v-else>
                <RouterLink to="/mypage" class="btn-login"></RouterLink>
              </div>
              <RouterLink to="/cs-contacts" class="btn-support"></RouterLink>
              <RouterLink to="/search" class="btn-search"></RouterLink>
              <button class="btn-sitemap" @click="pcSitemapToggle()">
                <span class="blind">전체메뉴</span>
              </button>
            </div>
          </div>
        </div>
      </header>
      <!-- //pc header -->

      <!-- mobile header -->
      <header class="header-mobile">
        <div class="header__inner">
          <h1 class="header-mobile__logo">
            <RouterLink to="/">
              <img src="@/assets/images/logo.png" alt="Linker" />
            </RouterLink>
          </h1>
          <div class="header-mobile__util">
            <RouterLink to="/search" class="btn-search">
              <span class="blind">통합검색</span>
            </RouterLink>
            <button class="btn-sitemap" @click="mobileSitemapToggle">
              <span class="blind">전체메뉴</span>
            </button>
          </div>
        </div>
        <nav class="gnb show" style="tabindex: 0" v-show="isMobileSitemapShow">
          <ul class="gnb__wrap">
            <li class="gnb__depth01">
              <RouterLink to="/bm" class="gnb__a01" @click.native="mobileSitemapToggle">빅바이어 마케팅</RouterLink>
            </li>
            <li class="gnb__depth01">
              <RouterLink
                to="/buyer-lists"
                class="gnb__a01"
                @click.native="mobileSitemapToggle"
              >바이어 정보</RouterLink>
            </li>
            <li class="gnb__depth01">
              <RouterLink
                to="/apply-reports"
                class="gnb__a01"
                @click.native="mobileSitemapToggle"
              >해외기업 보고서</RouterLink>
            </li>
            <li class="gnb__depth01">
              <RouterLink
                to="/apply-consults"
                class="gnb__a01"
                @click.native="mobileSitemapToggle"
              >수출바우처 컨설팅</RouterLink>
            </li>
            <li class="gnb__depth01">
              <RouterLink
                to="/cs-contacts"
                class="gnb__a01"
                @click.native="mobileSitemapToggle"
              >고객센터</RouterLink>
            </li>
            <li v-if="this.isLogin" class="gnb__depth01">
              <RouterLink to="/mypage" class="gnb__a01" @click.native="mobileSitemapToggle">마이페이지</RouterLink>
            </li>
            <li v-else>
              <RouterLink to="/login" class="gnb__a01" @click.native="mobileSitemapToggle">로그인</RouterLink>
            </li>
            <li v-if="this.isLogin" class="gnb__depth01">
              <span class="gnb__a01" @click="logout(true)">로그아웃</span>
            </li>
            <li v-else>
              <RouterLink to="/sign-up" class="gnb__a01" @click.native="mobileSitemapToggle">회원가입</RouterLink>
            </li>
          </ul>
          <button type="button" class="btn-gnb-close" @click="mobileSitemapToggle">
            <span class="blind">닫기</span>
          </button>
        </nav>
      </header>
      <!-- //mobile header -->

      <!-- sitemap(pc) -->
      <div class="sitemap show" style="tabindex: 0" v-show="isPcSitemapShow">
        <div class="sitemap__inner">
          <h1 class="header__logo">
            <RouterLink to="/">
              <img src="@/assets/images/logo.png" alt="Linker" />
            </RouterLink>
          </h1>
          <div class="sitemap__gnb">
            <ul class="sitemap__list">
              <li>
                <RouterLink
                  to="/bm"
                  class="sitemap__depth01"
                  @click.native="pcSitemapToggle()"
                >빅바이어 마케팅</RouterLink>
                <ul>
                  <li>
                    <RouterLink
                      to="/bm/big-data"
                      class="sitemap__depth02"
                      @click.native="pcSitemapToggle()"
                    >빅데이터 바이어 발굴</RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to="/bm/push"
                      class="sitemap__depth02"
                      @click.native="pcSitemapToggle()"
                    >마케팅 메시지 발송</RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to="/bm/history"
                      class="sitemap__depth02"
                      @click.native="pcSitemapToggle()"
                    >마케팅 메시지 발송내역</RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to="/bm/tmp"
                      class="sitemap__depth02"
                      @click.native="pcSitemapToggle()"
                    >임시저장내역</RouterLink>
                  </li>
                </ul>
              </li>
              <li>
                <RouterLink
                  to="/buyer-lists"
                  class="sitemap__depth01"
                  @click.native="pcSitemapToggle()"
                >바이어 정보</RouterLink>
                <ul>
                  <li>
                    <RouterLink
                      to="/buyer-lists"
                      class="sitemap__depth02"
                      @click.native="pcSitemapToggle()"
                    >바이어조회</RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to="/find-scambuyers"
                      class="sitemap__depth02"
                      @click.native="pcSitemapToggle()"
                    >사기바이어 이메일</RouterLink>
                  </li>
                </ul>
              </li>
              <li>
                <RouterLink
                  to="/apply-reports"
                  class="sitemap__depth01"
                  @click.native="pcSitemapToggle()"
                >해외기업 보고서</RouterLink>
                <ul>
                  <li>
                    <RouterLink
                      to="/apply-reports"
                      class="sitemap__depth02"
                      @click.native="pcSitemapToggle()"
                    >보고서신청</RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to="/report-lists"
                      class="sitemap__depth02"
                      @click.native="pcSitemapToggle()"
                    >보고서신청내역</RouterLink>
                  </li>
                </ul>
              </li>
              <li>
                <RouterLink
                  to="/apply-consults"
                  class="sitemap__depth01"
                  @click.native="pcSitemapToggle()"
                >수출바우처 컨설팅</RouterLink>
                <ul>
                  <li>
                    <RouterLink
                      to="/apply-consults"
                      class="sitemap__depth02"
                      @click.native="pcSitemapToggle()"
                    >컨설팅 신청</RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to="/consult-lists"
                      class="sitemap__depth02"
                      @click.native="pcSitemapToggle()"
                    >신청내역</RouterLink>
                  </li>
                </ul>
              </li>
            </ul>
            <ul class="sitemap__list">
              <li>
                <RouterLink
                  to="/cs-contacts"
                  class="sitemap__depth01"
                  @click.native="pcSitemapToggle()"
                >고객지원</RouterLink>
                <ul>
                  <li>
                    <RouterLink
                      to="/cs-contacts"
                      class="sitemap__depth02"
                      @click.native="pcSitemapToggle()"
                    >문의하기</RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to="/cs-notices"
                      class="sitemap__depth02"
                      @click.native="pcSitemapToggle()"
                    >공지사항</RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to="/cs-faq"
                      class="sitemap__depth02"
                      @click.native="pcSitemapToggle()"
                    >FAQ</RouterLink>
                  </li>
                </ul>
              </li>
              <!-- 로그아웃 상태 노출 -->
              <div v-if="this.isLogin == false">
                <li>
                  <RouterLink
                    to="/login"
                    class="sitemap__depth01"
                    @click.native="pcSitemapToggle()"
                  >로그인</RouterLink>
                  <RouterLink
                    to="/sign-up"
                    class="sitemap__depth01"
                    @click.native="pcSitemapToggle()"
                  >회원가입</RouterLink>
                </li>
              </div>
              <!-- 로그인 상태 노출 -->
              <div v-else>
                <li>
                  <RouterLink
                    to="/mypage"
                    class="sitemap__depth01"
                    @click.native="pcSitemapToggle()"
                  >마이페이지</RouterLink>
                </li>
              </div>
              <div v-if="this.isLogin == true">
                <li>
                  <div class="sitemap__depth01 pointer" @click="openQuickMessage">
                    메시지알림 (
                    <em class="txt-count">{{ newMessageCnt }}</em> )
                  </div>
                </li>
              </div>
            </ul>
          </div>

          <div class="util-menu">
            <div class="util-menu__wrap">
              <button type="button" class="btn-login">
                <span class="blind">로그인</span>
              </button>
              <button type="button" class="btn-support">
                <span class="blind">고객센터</span>
              </button>
              <button type="button" class="btn-search">
                <span class="blind">통합검색</span>
              </button>
              <button type="button" class="btn-sitemap-close">
                <span class="blind">닫기</span>
              </button>
            </div>

            <template v-if="this.isLogin == true">
              <div class="log-info">
                <div class="log-info__welcom">
                  <strong>{{ getUserNm }}</strong>님 안녕하세요.
                </div>
                <dl class="log-info__time">
                  <dt style="font-weight: bold">최근 로그인 일시</dt>
                  <dd>{{ getUserLoginDate }}</dd>
                </dl>
                <br />
                <dl class="log-info__time">
                  <dt style="font-weight: bold">자동 로그아웃까지 남은 시간</dt>
                  <dd>{{ getSessionTime }}</dd>
                </dl>
                <br />
                <div>
                  <button
                    type="button"
                    class="btn-login-keep"
                    style="margin-left: 0px"
                    @click="loginExtensions"
                  >로그인연장</button>
                  <span class="gnb__depth01">
                    <button class="btn-login-keep" @click="logout(true)">로그아웃</button>
                  </span>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="util-menu">
          <div class="util-menu__wrap">
            <div v-if="this.isLogin == false">
              <RouterLink to="/login" class="btn-login" @click.native="pcSitemapToggle()"></RouterLink>
            </div>
            <div v-else>
              <RouterLink to="/mypage" class="btn-login" @click.native="pcSitemapToggle()"></RouterLink>
            </div>
            <RouterLink to="/cs-contacts" class="btn-support" @click.native="pcSitemapToggle()"></RouterLink>
            <RouterLink to="/search" class="btn-search" @click.native="pcSitemapToggle()"></RouterLink>
            <button type="button" class="btn-sitemap-close" @click="pcSitemapToggle()">
              <span class="blind">닫기</span>
            </button>
          </div>
        </div>
      </div>
      <!-- //sitemap -->
    </div>

    <transition name="slide">
      <div v-if="isQuickMessageShow">
        <QuickMsg
          :isQuickMessageShow="isQuickMessageShow"
          @isQuickMessageShow="quickMessageToggle"
        />
      </div>
    </transition>
    <div v-if="isFavorBuyerShow">
      <InterestedBuyerPopup @favorBuyerToggle="favorBuyerToggle" />
    </div>
    <template v-if="getIsLogoutShow">
      <LogoutNotification />
    </template>
    <template v-if="getIsLogoutGuideShow">
      <LogoutGuide />
    </template>
    <ModalAlert ref="ModalAlert" />
    <ModalConfirm ref="ModalConfirm" />
  </div>
</template>
<style>
.slide-enter-to {
  top: 0;
  bottom: 0;
  right: -403px;
  z-index: 20;
}
.slide-enter-active {
  transition: all 0.3s ease;
}
.slide-enter {
  transform: translateX(100%);
}
</style>
<script>
import { mapGetters } from "vuex";
import { getNewMsgCnt } from "@/api/common";
import { loginExtension } from "@/api/user";
import QuickMsg from "@/components/common/QuickMsg.vue";
import InterestedBuyerPopup from "@/components/my/InterestedBuyerPopup.vue";
import LogoutNotification from "@/components/common/LogoutNotification.vue";
import LogoutGuide from "@/components/common/LogoutGuide.vue";
import ModalAlert from "@/components/common/ModalAlert.vue";
import ModalConfirm from "@/components/common/ModalConfirm.vue";

export default {
  components: {
    ModalAlert,
    ModalConfirm,
    QuickMsg,
    InterestedBuyerPopup,
    LogoutNotification,
    LogoutGuide,
  },
  data: () => ({
    width: 570,
    height: 610,
    isPcSitemapShow: false,
    isMobileSitemapShow: false,
    isQuickMessageShow: false,
    isFavorBuyerShow: false,
    isNavShow: false,
    hasNewAlarm: false,
    newMessageCnt: 0,
    isLogoutShow: false,
    isLogoutGuideShow: false,
    tid: 0,
    cnt: 1,
    restSec: 0,
    loginDate: "",
  }),

  watch: {
    isPcSitemapShow: function () {
      if (this.isLogin && this.isPcSitemapShow) {
        this.getNewMsgCnt();
      }
    },

    getTime: function () {
      if (this.isLogin) {
        if (this.getTime % 60 === 0) {
          this.getNewMsgCnt();
        }
        if (1 < this.getTime && this.getTime < 600) {
          this.$store.dispatch("userStore/LOGOUT_SHOW_TRUE");
        }
        if (this.getTime < 1) {
          this.autoLogout();
        }
      }
    },
  },
  computed: {
    ...mapGetters("userStore", ["getTime"]),
    ...mapGetters("userStore", ["isLogin"]),
    ...mapGetters("userStore", ["getUserNm"]),
    ...mapGetters("userStore", ["getUserLoginDate"]),
    ...mapGetters("userStore", ["getSessionTime"]),
    ...mapGetters("userStore", ["getSessionTime2"]),
    ...mapGetters("userStore", ["getIsLogoutShow"]),
    ...mapGetters("userStore", ["getIsLogoutGuideShow"]),
  },
  mounted() {
    if (this.isLogin) {
      this.$store.dispatch("userStore/START_SESSION_TIMER");
    }
  },
  methods: {
    autoLogout() {
      this.$store.dispatch("userStore/LOGOUT");
      this.$store.dispatch("userStore/LOGOUT_SHOW_FALSE");
      this.$store.dispatch("userStore/LOGOUT_GUIDE_SHOW_TRUE");
      if (this.$route.path !== "/") {
        this.$router.push("/login");
      }
    },

    logout(isMobile) {
      const flag = this.$refs.ModalConfirm.show({
        title: "확인",
        content: "로그아웃 하시겠습니까?",
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      }).then((result) => {
        if (result) {
          if (isMobile) {
            this.mobileSitemapToggle();
          } else {
            this.pcSitemapToggle();
          }
          this.userLogout();
          this.callAlert("로그아웃 되었습니다.");
        }
      });
    },
    userLogout() {
      this.$store.dispatch("userStore/LOGOUT");
      this.$router.history.current.fullPath === "/"
        ? this.$router.go()
        : this.$router.push("/");
    },
    callAlert(message) {
      this.$refs.ModalAlert.show({
        title: "확인",
        content: `${message}`,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      });
    },
    pcSitemapToggle(isPcSitemapShow) {
      if (isPcSitemapShow != undefined) {
        this.isPcSitemapShow = isPcSitemapShow;
      } else {
        this.isPcSitemapShow = !this.isPcSitemapShow;
      }
    },
    mobileSitemapToggle() {
      this.isMobileSitemapShow = !this.isMobileSitemapShow;
    },
    quickMessageToggle() {
      this.isQuickMessageShow = !this.isQuickMessageShow;
    },
    favorBuyerToggle(isFavorBuyerShow) {
      if (!this.isLogin && isFavorBuyerShow) {
        this.callAlert("로그인이 필요한 기능입니다.");
        return;
      }

      this.isFavorBuyerShow = isFavorBuyerShow;
    },
    showNav() {
      this.isNavShow = true;
    },
    hideNav() {
      this.isNavShow = false;
    },
    openQuickMessage(isPcSitemapShow) {
      if (!this.isLogin) {
        this.callAlert("로그인이 필요한 기능입니다.");
        return;
      }

      this.pcSitemapToggle(isPcSitemapShow);
      this.quickMessageToggle();
    },
    async getNewMsgCnt() {
      if (this.isLogin) {
        try {
          const { data } = await getNewMsgCnt();
          this.newMessageCnt = data.data;
        } catch (err) {
          if (
            err.response.data.status === 401 &&
            err.response.data.code === "A003"
          ) {
            return;
          }
          let content = "데이터를 불러오는 중 오류가 발생하였습니다.";
          if (err.response != undefined) {
            content = err.response.data.message;
          }

          this.$refs.ModalAlert.show({
            title: "알림",
            content: content,
            confirmButtonText: "닫기",
          }).then((result) => {
            if (result) {
              this.pcSitemapToggle();
            }
          });
        }
      }
    },
    async loginExtensions() {
      try {
        const { data } = await loginExtension();
        await this.$store.dispatch("userStore/LOGIN_EXTENSION", data.data);
      } catch (err) {
        if (
          err.response.data.status === 401 &&
          err.response.data.code === "A003"
        ) {
          return;
        }

        this.$store.dispatch("userStore/LOGOUT_SHOW_FALSE");
        this.callAlert("로그인 연장에 실패하였습니다.<br>다시 로그인해주세요.");
        if (this.$route.path !== "/login") {
          this.$router.push("/login");
        }
      }
    },
  },
};
</script>