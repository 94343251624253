import { instanceWithAuth, blobResponseInstanceWithAuth } from "@/api/index";

function getCountryCodes() {
    return instanceWithAuth.get("/commons/country-codes");
}

function sicCode(commCd) {
    return instanceWithAuth.post("/commons/sic-cd", commCd);
}

function getFile(param) {
    return blobResponseInstanceWithAuth.get("/commons/file", { params: param });
}

function getQuickMessageList() {
    return instanceWithAuth.get("/commons/push-history");
}

function readQuickMessage(param) {
    return instanceWithAuth.post("/commons/push-message", param);
}

function getBuyerContacts(param) {
    return instanceWithAuth.get("/commons/buyer-contacts", { params: param });
}

function getBuyerMessages(param) {
    return instanceWithAuth.get("/commons/buyer-messages", { params: param });
}

function getNewMsgCnt() {
    return instanceWithAuth.get("commons/new-message-cnt");
}

function getKeywords() {
    return instanceWithAuth.get("commons/keywords");
}

function getCategory() {
    return instanceWithAuth.get("/commons/category")
}

function getFaqCodes() {
    return instanceWithAuth.get("/commons/faq")
}

function getNoticeCodes() {
    return instanceWithAuth.get("/commons/notice")
}

export {
    getCountryCodes,
    sicCode,
    getFile,
    getQuickMessageList,
    readQuickMessage,
    getBuyerContacts,
    getBuyerMessages,
    getNewMsgCnt,
    getKeywords,
    getCategory,
    getFaqCodes,
    getNoticeCodes,
};