<template>
  <div class="quick-message" id="quickMessage">
    <div class="quick-message__inner">
      <h2 class="quick-message__title">알림</h2>
      <div class="quick-message__list">
        <div
          v-for="(item, i) in history"
          :key="i"
          class="message-box pointer"
          :class="{'message-box--open' : item.readYn == 'Y'}"
          @click="read(item)"
        >
          <span class="message-box__date">{{item.regDtim}}</span>

          <span v-if="item.pushGbCd == '3'" class="message-box__text">
            <strong>{{item.content.substring(0, item.content.indexOf('|'))}}</strong>
            {{item.content.substring(item.content.indexOf('|') + 1)}}
          </span>
          <span v-else class="message-box__text">{{item.content}}</span>
        </div>
      </div>
      <button type="button" class="quick-message__close" @click="close">
        <span class="blind">닫기</span>
      </button>
    </div>
    <Winpop ref="winPopup" @onRecvEvtFromWinPop="val=>onRecvWinPop(val)" />
    <ModalAlert ref="ModalAlert" />
  </div>
</template>
<script>
import Winpop from "@/components/common/WindowPopup.vue";
import ModalAlert from "@/components/common/ModalAlert.vue";
import { getQuickMessageList, readQuickMessage } from "@/api/common";

export default {
  components: {
    Winpop,
    ModalAlert,
  },
  props: {
    isQuickMessageShow: Boolean,
  },
  data: () => {
    return {
      history: [],
    };
  },
  methods: {
    close() {
      let quickMessageWrap = document.querySelector(".quick-message");
      quickMessageWrap.animate(
        { right: ["-403px"] },
        { duration: 200, fill: "forwards" }
      );
      setTimeout(
        function () {
          this.$emit("isQuickMessageShow", false);
        }.bind(this),
        100
      );
    },
    async getList() {
      try {
        const { data } = await getQuickMessageList();
        this.history = data.data;
      } catch (err) {
        if (
          err.response.data.status === 401 &&
          err.response.data.code === "A003"
        ) {
          this.close();
          return;
        }

        this.$refs.ModalAlert.show({
          title: "알림",
          content: "데이터를 불러오는 중 오류가 발생하였습니다.",
          confirmButtonText: "닫기",
        }).then((result) => {
          if (result) {
            this.close();
          }
        });
      }
    },
    read(item) {
      if (item.readYn == "N") {
        const param = {
          pushNo: item.pushNo,
        };

        readQuickMessage(param)
          .then((response) => {
            item.readYn = "Y";
          })
          .catch((error) => {
            this.$refs.ModalAlert.show({
              title: "알림",
              content: "읽음 처리 중 오류가 발생하였습니다.",
              confirmButtonText: "닫기",
            }).then((result) => {
              if (result) {
                this.close();
              }
            });
            return;
          });
      }

      if (item.pushGbCd == "2") {
        this.$router.push("/bm/history").catch(() => {});
        this.close();
      }

      if (item.pushGbCd == "3") {
        this.openMessage(item.dunsNo, item.contactSeq);
      }

      if (item.pushGbCd == "5" || item.pushGbCd == "6") {
        this.$router.push("/consult-lists").catch(() => {});
        this.close();
      }
    },
    openMessage(dunsNo, contactSeq) {
      this.$refs.winPopup.openWinPop(
        `/message/${dunsNo}/${contactSeq}`,
        500,
        700
      );
    },
    onRecvWinPop(param) {
      if (param != null && param.source == "vue-devtools-backend") {
        // vue 개발자모드 툴 설치 후 팝업 오픈 시 닫히는 현상 발생하여 임시조치
        return;
      }
      this.close();
      this.$refs.winPopup.closeWinPop();
      if (param.cd == "forward") {
        this.redirect("BmPush", {
          title: param.title,
          content: param.content,
          forwardFiles: param.forwardFiles,
        });
      } else if (param.cd == "reply") {
        this.redirect("BmPush", {
          isReply: param.isReply,
          buyers: [param.buyer],
          mktSndNo: param.mktSndNo,
          subject: param.title,
          content: param.content,
        });
      } else if (param.cd == "sendMkt") {
        this.redirect("BmPush", { buyers: [param.buyer] });
      }
    },
    redirect(name, param) {
      // TODO 로직 수정
      // 마케팅 발송 페이지(/bm/push)에서 똑같이 마케팅 발송 페이지로 이동 불가 (redundant 에러 발생)
      // -> 새로고침 (router.go()): vuex store 초기화되어 사용 불가, persistedStore 시도 가능
      // -> 새창(window.open): vuex store 초기화되어 사용 불가, persistedStore 시도 가능
      // -> 해당 페이지에서 vuex store state 변수에 watch 걸어놓고 갱신하는 로직 시도 가능
      // --> 일단은 동일한 페이지 2개 생성하여 번갈아 호출하도록 하였음.
      if (this.$router.currentRoute.name == name) {
        if (this.$router.currentRoute.name == "BmPush") {
          this.$router.push({ name: "BmPush2", params: param });
        } else if (this.$router.currentRoute.name == "BmPush2") {
          this.$router.push({ name: "BmPush", params: param });
        }
      } else {
        this.$router.push({ name: name, params: param });
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>