<template>
  <div class="modal-popup modal-popup--large modal-popup--show" id="interestedBuyers">
    <div class="modal-popup__wrap">
      <div class="modal-popup__head">
        <h1 class="modal-popup__title">관심 바이어</h1>
      </div>
      <div class="modal-popup__body">
        <div class="modal-popup__content">
          <div class="board-search">
            <div class="board-search__row">
              <dl class="board-search__col">
                <dt class="board-search__label">
                  <label for="sel02">국가</label>
                </dt>
                <dd class="board-search__input">
                  <span class="input-select">
                    <select name="sel02" id="sel02" v-model="natCd">
                      <option value>국가를 선택해주세요.</option>
                      <option
                        :key="index"
                        :value="item.natCd"
                        v-for="(item, index) in countryCodes"
                      >{{ item.natCdNm }} | {{ item.natCd }}</option>
                    </select>
                  </span>
                </dd>
              </dl>
            </div>
            <div class="board-search__row">
              <dl class="board-search__col">
                <dt class="board-search__label">
                  <label for="inpSearchKeyword">키워드</label>
                </dt>
                <span class="input-select">
                  <select v-model="gubunCd" style="width: 100px">
                    <option value="001">기업명</option>
                    <option value="002">던스번호</option>
                    <option value="003">관심품목</option>
                  </select>
                </span>
                <dd class="board-search__input input--autokeyword input-search__margin">
                  <input
                    type="text"
                    id="inpSearchKeyword"
                    class="input-text"
                    placeholder="DunsⓇNumber, Buyer Name, 관심품목"
                    autocomplete="off"
                    v-model="keyword"
                    @keyup.enter="getFavorBuyerList(true)"
                    @focus="showAutocomplete"
                    @blur="hideAutocomplete"
                  />
                  <!-- 키워드 자동완성 -->
                  <div class="auto-keyword">
                    <div
                      class="auto-keyword__wrap atkwd--auto"
                      id="autoKeyword"
                      v-if="
                        keyword.length > 0 &&
                        isAutocompleteShow &&
                        gubunCd == '003'
                      "
                    >
                      <div class="auto-keyword__head">
                        <div class="tit">키워드 자동완성</div>
                      </div>
                      <div class="auto-keyword__cont">
                        <ul
                          class="auto-keyword__list"
                          v-for="(item, index) in autocompleteList"
                          :key="index"
                        >
                          <li @click="clickAutocomplete(item)">
                            <a class="kwd">{{ item.categoryNm }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </dd>
              </dl>
            </div>
            <div class="board-search__row">
              <dt class="board-search__label">
                <label for="inpSearch04">산업분류코드</label>
              </dt>
              <dd class="board-search__itemSelect">
                <button
                  type="button"
                  class="btn-pri btn--small"
                  data-roll="modal-popup"
                  data-popid="modalIndcd"
                  @click="isSicCdPopupShow = true"
                >코드조회</button>
                <span v-for="(sicObject, index) in sicCdArr" :key="index" class="selected-item">
                  <button type="button" class="btn-del" @click="deleteSicCd(index)">
                    <em class="blind">삭제</em>
                  </button>
                  {{ sicObject.cdNm }}
                </span>
              </dd>
            </div>
            <!-- 20231003 조회버튼 추가 -->
            <div class="btn-wrap">
              <button type="button" class="btn-sec btn--small" @click="clear">초기화</button>
              <button type="button" class="btn-pri btn--small" @click="getFavorBuyerList(true)">
                조회
                <i class="icon16-search-wt"></i>
              </button>
            </div>
          </div>
          <div class="board-head">
            <div class="board-head__left">
              <span class="board-count">
                총
                <em>{{ totalCnt.toLocaleString() }}</em>
                건
              </span>
            </div>
          </div>
          <div class="board-list">
            <table class="board-list__table">
              <caption>산업분류코드, 산업명 선택 표</caption>
              <colgroup>
                <!-- <col style="width: 6%" /> -->
                <col style="width: 7%" />
                <col style="width: 10%" />
                <col style="width: 28%" />
                <col style="width: 150px" />
                <col style="width: 150px" />
                <col style="width: auto" />
              </colgroup>
              <thead>
                <tr>
                  <!-- <th scope="col">
                    <span class="input-checkbox">
                      <input type="checkbox" name="ch01" id="ch01_01_inter" />
                      <label for="ch01_01_inter">
                        <span class="blind">125</span>
                      </label>
                    </span>
                  </th>-->
                  <th scope="col">이메일</th>
                  <th scope="col">국가</th>
                  <th scope="col">기업명</th>
                  <th scope="col">DUNSⓇNumber</th>
                  <th scope="col">산업분류코드</th>
                  <th scope="col">산업분야</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="favorBuyerList.length == 0">
                  <td colspan="6">검색 결과가 없습니다.</td>
                </tr>
                <tr v-for="(buyer, index) in favorBuyerList" v-bind:key="index">
                  <!-- <td>
                    <span class="input-checkbox">
                      <input type="checkbox" name="ch01" />
                      <label>
                        <span class="blind">125</span>
                      </label>
                    </span>
                  </td>-->
                  <td>{{ buyer.emailCnt }} 건</td>
                  <td>{{ buyer.natCd }}</td>
                  <td class="text-left">
                    <span class="input-like">
                      <input
                        type="checkbox"
                        name="btnLike"
                        @click="updateFavor(buyer)"
                        v-model="buyer.favorYn"
                        true-value="Y"
                        false-value="N"
                      />
                      <label class="btnLike01">
                        <span class="blind">관심바이어</span>
                      </label>
                    </span>
                    <button @click="goToBuyerDetailPage(buyer)">{{ buyer.cmpNm }}</button>
                    <span
                      class="badge"
                      v-if="buyer.krTradeYn == 'Y' && getUserAuthorityCode == 1"
                    >한국거래바이어</span>
                  </td>
                  <td>{{ formatDunsNumber(buyer.dunsNo) }}</td>
                  <td>{{ buyer.sicCd }}</td>
                  <td class="text-left">{{ buyer.bnss }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btn-wrap">
            <button
              type="button"
              class="btn-more"
              @click="getFavorBuyerList(false)"
              v-if="favorBuyerList.length < totalCnt"
            >더보기</button>
          </div>
        </div>
      </div>
      <div class="modal-popup__close">
        <button type="button" @click="popupClose">
          <span class="blind">팝업닫기</span>
        </button>
      </div>
    </div>
    <BuyerDetailConfirmPopup
      v-if="isBuyerConfirmPopupShow == true"
      :isBuyerConfirmPopupShow="isBuyerConfirmPopupShow"
      @isBuyerConfirmPopupShow="isBuyerConfirmPopupShow = false"
      @goToChargeCashPage="goToChargeCashPage"
      @closeFavorBuyerPopup="popupClose"
      :isFromFavorBuyer="true"
      :buyer="selectedBuyer"
      :payAmount="500"
    />
    <IndCdFindPopup
      v-if="isSicCdPopupShow === true"
      v-on:selectedEvent="addSicCd"
      @modalEvent="ShowSicCdPopup"
    ></IndCdFindPopup>
    <LoadingBar ref="loading" />
    <ModalConfirm ref="ModalConfirm" />
    <ModalAlert ref="ModalAlert" />
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { getBuyerFavor, getFavorBuyerList } from "@/api/buyer";
import IndCdFindPopup from "@/components/common/IndCdFindPopup.vue";
import BuyerDetailConfirmPopup from "@/components/buyer/BuyerDetailConfirmPopup.vue";
import LoadingBar from "@/components/common/LoadingBar.vue";
import ModalAlert from "@/components/common/ModalAlert.vue";
import ModalConfirm from "@/components/common/ModalConfirm.vue";
export default {
  components: {
    IndCdFindPopup,
    BuyerDetailConfirmPopup,
    LoadingBar,
    ModalAlert,
    ModalConfirm,
  },
  computed: {
    ...mapState("commonStore", ["countryCodes"]),
    ...mapGetters("commonStore", ["categories"]),
    ...mapGetters("userStore", ["getUserAuthorityCode"]),
  },
  data() {
    return {
      isBuyerConfirmPopupShow: false,
      isSicCdPopupShow: false,
      isAutocompleteShow: false,
      autocompleteList: [],
      selectedBuyer: {},
      totalCnt: 0,
      natCd: "",
      keyword: "",
      categoryNo: "",
      sicCdArr: [],
      gubunCd: "001", //001: 기업명 , 002: 던스번호, 003: 관심품목
      filter: {},
      favorBuyerList: [],
    };
  },
  mounted() {
    this.$store.dispatch("commonStore/GET_CATEGORIES");
    this.$store.dispatch("commonStore/GET_COUNTRY_CODES");
    this.getFavorBuyerList(true);
  },
  watch: {
    keyword: function (newValue, oldValue) {
      this.filterKeywords();
    },
  },
  methods: {
    hideAutocomplete() {
      setTimeout(() => {
        this.isAutocompleteShow = false;
      }, 200);
    },
    showAutocomplete() {
      this.isAutocompleteShow = true;
    },
    clickAutocomplete(item) {
      this.categoryNo = item.categoryNo;
      this.keyword = item.categoryNm; //선택한 값 검색창에 추가
      this.isAutocompleteShow = false;
    },
    filterKeywords() {
      this.autocompleteList = this.categories.filter(
        (item) =>
          item.categoryNm.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
      );
    },
    async getFavorBuyerList(isFirstRequest) {
      if (isFirstRequest) {
        // 첫 진입시, 조회버튼 클릭시, 검색창 엔터 시 필터 초기화
        this.filter = {
          natCd: this.natCd,
          keyword: this.keyword,
          categoryNo: this.categoryNo,
          sicCdArr: this.sicCdArr,
          gubunCd: this.gubunCd,
          listCnt: 0,
        };
      } else {
        // 더보기시 페이징을 위해 카운트 변수만 변경해줌
        this.filter.listCnt = this.favorBuyerList.length;
      }

      try {
        this.$refs.loading.on();
        const { data } = await getFavorBuyerList(this.filter);
        if (isFirstRequest) {
          this.favorBuyerList = [];
        }
        this.totalCnt = data.data.buyerListCount;
        this.favorBuyerList.push(...data.data.buyerList);
      } catch (error) {
        if (
          error.response.data.status === 401 &&
          error.response.data.code === "A003"
        ) {
          return;
        }
        this.callAlert("바이어 조회 중 오류가 발생하였습니다.");
      } finally {
        this.$refs.loading.off();
      }
    },
    formatDunsNumber(number) {
      if (number != null)
        return (
          number.slice(0, 2) + "-" + number.slice(2, 5) + "-" + number.slice(5)
        );
      else return " ";
    },
    clear() {
      this.natCd = "";
      this.keyword = "";
      this.categoryNo = "";
      this.sicCdArr = [];
      this.pageNo = 1;
      this.gubunCd = "001";
    },
    async updateFavor(buyer) {
      const params = {
        dunsNo: buyer.dunsNo,
      };

      try {
        this.$refs.loading.on();
        const { data } = await getBuyerFavor(params);
        buyer.favorYn = buyer.favorYn == "Y" ? "N" : "Y";
      } catch (error) {
        if (
          error.response.data.status === 401 &&
          error.response.data.code === "A003"
        ) {
          return;
        }

        this.callAlert("관심 바이어 변경 중 에러가 발생하였습니다.");
      } finally {
        this.$refs.loading.off();
      }
    },
    goToChargeCashPage() {
      this.$router.push({
        name: "MyPage",
      });
    },
    async goToBuyerDetailPage(buyer) {
      if (this.getUserAuthorityCode > 2) {
        this.callAlert("BASIC등급부터 사용 가능합니다");
      } else {
        /*
         * 1. 바이어 상세 조회를 한적이 있다. 그낭 상세로 이동
         * 2. 상세 조회 한적이 없다, 캐시차감.
         */
        if (buyer.chargeBuyerDetail) {
          this.popupClose();
          this.$router.push({
            name: "buyerDetail",
            params: { dunsNo: buyer.dunsNo },
          });
        } else {
          this.isBuyerConfirmPopupShow = true;
          this.selectedBuyer = buyer;
        }
      }
    },
    addSicCd(data) {
      if (data.length + this.sicCdArr.length > 3) {
        this.callAlert(
          "산업분류코드는 3개까지 선택가능합니다.<br> 다시 선택해 주세요."
        );
        return;
      }
      for (let i = 0; i < data.length; i++) {
        this.sicCdArr.push(data[i]);
      }
    },
    deleteSicCd(index) {
      this.sicCdArr.splice(index, 1);
    },
    popupClose() {
      this.$emit("favorBuyerToggle", false);
    },
    callAlert(message) {
      this.$refs.ModalAlert.show({
        title: "확인",
        content: `${message}`,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      });
    },
  },
};
</script>