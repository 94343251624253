import { getFaqList } from "@/api/faq";

const faqStore = {
    namespaced: true,
    state: {
        faqList: [],
        totalCnt: 0,
        pageNo: 1,
    },
    getters: {
        getFaqList(state) {
            return state.faqList;
        },
        isOpen: (state) => (index) => {
            return state.faqList[index]?.open || false;
        },
    },
    mutations: {
        SET_FAQ_LIST(state, faqList) {
            state.faqList = faqList;
        },
        ADD_FAQ_LIST(state, faqList) {
            state.faqList.push(...faqList);
        },
        CLEAR_NOTICE_LIST(state) {
            state.faqList = [];
        },
        SET_TOTAL_CNT(state, totalCnt) {
            state.totalCnt = totalCnt;
        },
        SET_PAGE_NO(state, pageNo) {
            state.pageNo = pageNo;
        },
        SET_IS_OPEN(state, { index, isOpen }) {
            if (state.faqList[index]) {
                state.faqList[index].open = isOpen;
            }
        },
    },
    actions: {
        async GET_FAQ_LIST({ commit }, params) {
            const { data } = await getFaqList(params);
            if (params.pageNo == 1) {
                commit("SET_FAQ_LIST", data.data.list);
            } else {
                commit("ADD_FAQ_LIST", data.data.list);
            }
            commit("SET_TOTAL_CNT", data.data.totalCnt);
        },
        async SET_PAGE_NO({ commit }, pageNo) {
            commit("SET_PAGE_NO", pageNo);
        },
        TOGGLE_ACCORDION({ commit, state }, index) {
            const isOpen = !state.faqList[index]?.open;
            commit("SET_IS_OPEN", { index, isOpen });
        },
    },

}

export default faqStore;