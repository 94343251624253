<template>
  <!-- [팝업]산업분류코드 조회 -->
  <div class="modal-popup" id="modalIndcd">
    <div class="modal-popup__wrap">
      <div class="modal-popup__head">
        <h1 class="modal-popup__title">산업분류코드 조회</h1>
      </div>
      <div class="modal-popup__body">
        <div class="modal-popup__content">
          <!-- 조건검색 -->
          <div class="board-search">
            <div class="board-search__row">
              <dl class="board-search__col">
                <dt class="board-search__label blind">
                  <label for="sel01">산업분류코드</label>
                </dt>
                <dd class="board-search__input">
                  <span class="input-select">
                    <select v-model="commCd.cdGb" name="sel01" id="sel01">
                      <option value="2">표준산업명</option>
                      <option value="1">표준산업코드</option>
                    </select>
                  </span>
                </dd>
              </dl>
              <dl class="board-search__col flex-2">
                <dt class="board-search__label blind">
                  <label for="inpSearch">키워드</label>
                </dt>
                <dd class="board-search__input">
                  <div class="input-search">
                    <input
                      type="text"
                      name="inpSearch"
                      id="inpSearch"
                      class="input-search__input"
                      placeholder="키워드를 입력하세요."
                      v-model="commCd.keyword"
                      @keyup.enter="getsicCode"
                    />
                    <button type="button" @click="getsicCode" class="input-search__btn">
                      <span class="blind">검색</span>
                    </button>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <!-- //조건검색 -->

          <div class="board-head">
            <div class="board-head__left">
              <span class="board-count">
                총
                <em>{{ sicCodeArr.length.toLocaleString() }}</em> 건
              </span>
            </div>
            <div class="board-head__right">
              <span>(최대 {{availableCount}}개까지 선택)</span>
            </div>
          </div>
          <div class="board-list-headFixed">
            <div class="board-list fixed--head">
              <table class="board-list__table">
                <caption>산업분류코드, 산업명 선택 표</caption>
                <colgroup>
                  <!-- fixed body 와 동일 설정 -->
                  <col style="width: 10%" />
                  <col style="width: 15%" />
                  <col style="width: 30%" />
                  <col style="width: auto" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      <span class="input-checkbox">
                        <input
                          type="checkbox"
                          name="ch01"
                          id="ch01_01"
                          @click="selectAll"
                          v-model="isSelectedAll"
                        />
                        <label for="ch01_01">
                          <span class="blind">전체</span>
                        </label>
                      </span>
                    </th>
                    <th scope="col">No</th>
                    <th scope="col">산업분류코드</th>
                    <th scope="col">산업명</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div class="board-list fixed--body">
              <table class="board-list__table">
                <caption>산업분류코드, 산업명 선택 표</caption>
                <colgroup>
                  <!-- fixed head 와 동일 설정 -->
                  <col style="width: 10%" />
                  <col style="width: 15%" />
                  <col style="width: 30%" />
                  <col style="width: auto" />
                </colgroup>
                <tbody>
                  <tr v-for="(sicObject, index) in sicCodeArr" :key="index">
                    <td>
                      <span class="input-checkbox">
                        <input
                          :id="index"
                          type="checkbox"
                          name="ch01"
                          v-model="sicObject.checkSelected"
                        />
                        <label :for="index">
                          <!--@click="checkClick(index)" -->
                          <span class="blind">125</span>
                        </label>
                      </span>
                    </td>
                    <td>{{ index + 1 }}</td>
                    <td>{{ sicObject.cd }}</td>
                    <td class="text-left">{{ sicObject.cdNm }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-popup__foot">
        <div class="btn-wrap">
          <button type="button" class="btn-pri" @click="[confirmSelectCd()]">확인</button>
        </div>
      </div>
      <div class="modal-popup__close">
        <button type="button" @click="[popupClose(), $emit('modalEvent', false)]">
          <span class="blind">팝업닫기</span>
        </button>
      </div>
    </div>
    <ModalAlert ref="ModalAlert" />
    <LoadingBar ref="loading" />
  </div>
  <!-- //[팝업]산업분류코드 조회 -->
</template>
<script>
import { sicCode } from "@/api/common";
import ModalAlert from "@/components/common/ModalAlert.vue";
import LoadingBar from "@/components/common/LoadingBar.vue";
export default {
  components: { ModalAlert, LoadingBar },
  props: {
    isIndcdPopupShow: Boolean,
    availableCount: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      sicCodeArr: [],
      commCd: {
        keyword: "",
        cdGb: "2",
      },
      checkedIndex: [],
      selectedObject: [],
      isSelectedAll: false,
    };
  },
  mounted() {
    //console.log(userGrd)
    this.modalPopupOpen("modalIndcd");
    this.getsicCode();
  },

  methods: {
    selectAll() {
      // 1. 체크 안한 상태 false-> 체크 ture
      // 2. 체크 한 상태 true -> 체크 안함 flase
      if (this.isSelectedAll == false) {
        this.sicCodeArr.forEach((item) => (item.checkSelected = true));
      } else {
        this.sicCodeArr.forEach((item) => (item.checkSelected = false));
      }
    },
    //레어어팝업 열기
    modalPopupOpen(popUpID) {
      let openPopup = document.getElementById(popUpID);
      openPopup.classList.add("modal-popup--show");
      openPopup.setAttribute("tabindex", "0");
      openPopup.focus();
      document.body.style.overflow = "hidden";
    },
    popupClose() {
      this.modalPopupClose("modalIndcd");
      this.$emit("isIndcdPopupShow", false);
    },
    //레이어팝업 닫기
    modalPopupClose(popUpID) {
      let closePopup = document.getElementById(popUpID);
      closePopup.classList.remove("modal-popup--show");
      closePopup.removeAttribute("tabindex");
      document.body.removeAttribute("style");
    },
    /**
     * 1. 산업분류코드 받아온다.
     * 2. json에 체크박스 선택 여부 변수 추가
     * 3. 체크박스랑 변수랑 바인딩 하여 true/false를 체크
     */
    async getsicCode() {
      //국가코드 조회
      this.$refs.loading.on();
      try {
        const { data } = await sicCode(this.commCd);
        this.sicCodeArr = data.data; // 배열을 바로 대입 가능
        for (var idx = 0; idx < data.data.length; idx++) {
          this.sicCodeArr[idx].checkSelected = false;
        }
      } catch (error) {
        this.$refs.ModalAlert.error(error);
      } finally {
        this.$refs.loading.off();
      }
    },
    checkClick(index) {
      if (this.checkedIndex.includes(index)) {
        this.checkedIndex.splice(index, 1);
      } else {
        this.checkedIndex.push(index);
      }
    },
    confirmSelectCd() {
      this.selectedObject = [];
      for (var idx = 0; idx < this.sicCodeArr.length; idx++) {
        if (this.sicCodeArr[idx].checkSelected == true) {
          this.selectedObject.push(this.sicCodeArr[idx]);
        }
      }
      if (this.selectedObject.length > this.availableCount) {
        this.callAlert(
          `산업분류코드는 ${this.availableCount}개까지 선택가능합니다.`
        );
      } else if (this.selectedObject.length == 0) {
        this.callAlert("선택한 산업분류코드가 없습니다.");
      } else {
        this.$emit("selectedEvent", this.selectedObject);
        this.$emit("modalEvent", false);
        this.popupClose();
      }
    },
    callAlert(message) {
      const flag = this.$refs.ModalAlert.show({
        title: "확인",
        content: `${message}`,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      });
    },
  },
};
</script>