<template>
  <div class="loading" v-if="isLoading"><span class="blind">로딩중</span></div>
</template>
<script>
export default {
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    on() {
      this.isLoading = true;
    },
    off() {
      this.isLoading = false;
    }
  },
}
</script>