import { instanceWithAuth, multipartInstanceWithAuth } from "@/api/index";

function noticeBizCmpNm() {
    return instanceWithAuth.get("/customers/get-user-info");
}

function uploadContactForm(param) {
    return multipartInstanceWithAuth.post("/customers/upload-contact-form", param);
}

export { noticeBizCmpNm, uploadContactForm};
